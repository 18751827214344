import React, { useEffect } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

const bgImage = "/header.jpg"

export default () => {
	useEffect(() => {
		(function(e,t,n) {
			var u=n.queue||[];
			e.BookAppWidgetReady=n=function(e) {
					n.queue.push(e)
			};
			n.queue=u;
			var o="script",a=t.createElement(o),i=t.getElementsByTagName(o)[0];
			a.src="https://cdn.book.app/embed/v1.js";
			a.type="text/javascript";
			a.async=!0;
			a.addEventListener("load",function(){
				for(;n.queue.length;)n.queue.shift()()
			},!1);
			i.parentNode.insertBefore(a,i)
		})(window,document,window.BookAppWidgetReady||function(){});
		window.BookAppWidgetReady(function () {
			window.BookApp.widget('#bookapp-reservation-widget', 'birdsofbeautyparlour');
		});
	});
	return (
		<Layout>
			<SEO pageTitle="Book a treatment" />
			<Header pageTitle="Book a treatment" bgImage={bgImage} />
			<section>
				<div id="bookapp-reservation-widget" />
			</section>
		</Layout>
	)
}